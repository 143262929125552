import React from 'react';
import { graphql, Link } from 'gatsby';
import SEO from '../components/SEO/SEOPage';
import Layout from '../components/shared/layout';
import { breakpoints, wrapper } from '../utils/style';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import Parallax from "../components/shared/Backgrounds/Parallax";
import Icon404 from '../icons/svg/404';

const Wrapper = styled('div')`
  ${wrapper}
  padding: 200px 0;
  .img-block-wrap {
    max-width: 490px;
    margin: 0 auto 80px;
    position: relative;
  }
  .img-wrap {
    width: 40%;
    position: absolute;
    top: 1%;
    left: 32%;
  }
  .bottom-block {
    text-align: center;
  }

  @media (max-width: ${breakpoints.sm}) {
    padding: 100px 0;
    .img-block-wrap {
      width: 350px;
      margin-bottom: 48px;
      svg {
        max-width: 100%;
      }
    }
    .img-wrap {
      top: 14%;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    .img-block-wrap {
      width: 290px;
      margin-bottom: 0;
    }
    .img-wrap {
      top: 19%;
    }
  }
`;

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title='404: Not found'/>
    <Parallax />
    <Wrapper>
      <div className="img-block-wrap">
      <Icon404/>
        {data.balls?.childImageSharp?.fluid &&
        <div className="img-wrap">
          <Img alt="404" fluid={data.balls.childImageSharp.fluid} fadeIn={false} loading='eager' />
        </div>
        }
      </div>

      <div className="bottom-block">
        <Link to="/" className="button gradient-border-button">
          <span>ไปที่หน้าแรก</span>
        </Link>
      </div>
    </Wrapper>
  </Layout>
);

export default NotFoundPage;

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    balls: file(relativePath: { eq: "lotto_balls.png" }) {
      childImageSharp {
          fluid(maxWidth: 190, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }      
  }
`;
